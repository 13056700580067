const toCurrency = (currency, number, digits) => {

    if (!number) {
        return 'N/A'
    }
    
    const settings = {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: digits ? digits : 2
    };
    return Intl.NumberFormat('pt-BR', settings).format(number);
}

export default toCurrency;