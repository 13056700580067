import React, { createContext, useEffect } from 'react'
import EventPublisher from "../services/eventPublisher";

import useAuth from './hooks/useAuth'

const Context = createContext()

function AuthProvider({ children }) {
    const {
        authenticating, authenticated, loading, handleLogin, handleLogout, error, user
    } = useAuth()

    useEffect(() => {
        EventPublisher.instance = new EventPublisher([
            'ACCESS_TOKEN_EXPIRED',
        ]);

        const cancelEventSubscription = EventPublisher.instance.subscribe('ACCESS_TOKEN_EXPIRED', () => {
            handleLogout();
        })

        return cancelEventSubscription;
    }, [])

    return (
        <Context.Provider value={{ authenticating, authenticated, loading, handleLogin, handleLogout, error, user }}>
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider }