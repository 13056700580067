import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RiSearchLine } from "react-icons/ri"
import { BiDownload } from "react-icons/bi"
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

import {
    Box,
    Grid,
    TextField,
    CardContent,
    FormControl,
} from '@mui/material'
import { getFirstDayOfPreviousMonth, getLastDayOfPreviousMonth } from '../../utils/dateUtils'

import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'

import { CardStyled, DownloadButton } from './style'
import TitleCard from '../../components/Title'
import SelectStyled from '../../components/Select'
import Button from '../../components/DefaultButton'
import DatePicker from '../../components/DatePicker'
import TableCfe from '../../components/Tables/TableCfe'
import { getCfes, downloadCfes } from '../../services/cfeService'
import CircularProgress from '../../components/CircularProgress'
import CircularProgressDisabled from '../../components/CircularProgressDisabled'
import { Title } from '../../components/Title/style';

const validationSchema = yup.object({
    clienteId: yup.string().required("Selecione o cliente!"),
    dataDe: yup.date().required("Insira uma data!"),
    dataAte: yup.date().required("Insira uma data!"),
})

export default function Cfe() {
    const [cfes, setCfes] = useState([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [downloading, setDownloading] = useState(false)

    async function onSubmit(values, page = 0) {
        setIsLoading(true)
        setCfes([])
        try {
            const { clienteId, dataDe, dataAte } = values
            const response = await getCfes(clienteId, dataDe, dataAte, page)
            const _cfes = response.data.content
            setPage(page);
            setCfes(_cfes)
            setCount(response.data.totalElements)
            setTotal(response.data.total)
            setRowsPerPage(response.data.size)
        } catch (error) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setIsLoading(false)
        }
    }

    async function onDownload(values) {
        setDownloading(true)
        try {
            const { clienteId, dataDe, dataAte } = values
            const response = await downloadCfes(clienteId, dataDe, dataAte)
            const fileName = response.headers["content-disposition"].split('filename=')[1];
            saveAs(response.data, fileName);
        } catch (error) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setDownloading(false)
        }
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            clienteId: "",
            dataDe: getFirstDayOfPreviousMonth(),
            dataAte: getLastDayOfPreviousMonth(),
        }
    })

    return (
        <>
            <FormControl
                onSubmit={handleSubmit((values) => onSubmit(values))}
                sx={{
                    width: '100%',
                }}
            >
                <CardStyled>
                    <CardContent>
                        <Box
                            component="form"
                        >
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TitleCard
                                    pageName="CF-e"
                                    description="(Cupom Fiscal Eletrônico)"
                                />
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        padding: '1em 0'
                                    }}
                                >
                                    <Grid
                                        item
                                        sm={5}
                                    >
                                        <Controller
                                            control={control}
                                            name="clienteId"
                                            render={({ field }) => (
                                                <SelectStyled
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        setCfes([])
                                                        field.onChange(e)
                                                    }}
                                                    inputRef={field.ref}
                                                    displayEmpty
                                                    label="Clientes"
                                                    error={!!errors.clienteId}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={5}
                                    >
                                        <DatePicker
                                            control={control}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    error={!!errors.dataDe || !!errors.dataAte}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={2}
                                    >
                                        <Button
                                            type="submit"
                                            label="Buscar"
                                            startIcon={
                                                <RiSearchLine />
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        sx={{
                                            paddingTop: '0px!important',
                                            color: 'red'
                                        }}
                                    >
                                        <span>
                                            {errors.clienteId?.message}
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        sm={8}
                                        sx={{
                                            paddingTop: '0px!important',
                                            color: 'red'
                                        }}
                                    >
                                        <span>
                                            {!!errors.dataDe ? !!errors.dataDe && 'Insira uma data válida para os dois campos!' : !!errors.dataAte?.message && 'Insira uma data válida para os dois campos!'}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                >
                                    <DownloadButton
                                        label="Download"
                                        onClick={handleSubmit(onDownload)}
                                        startIcon={
                                            downloading ? <CircularProgressDisabled /> : <BiDownload />
                                        }
                                        disabled={cfes.length === 0 || downloading}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </CardStyled>
                {
                    isLoading && (<CircularProgress />)
                }

                {
                    cfes.length === 0 &&
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {
                                !isLoading ?
                                    <Title>
                                        Nenhum registro encontrado...
                                    </Title> :
                                    null
                            }
                        </Grid>
                    </Grid>
                }

                {
                    cfes.length > 0 &&
                    <TableCfe
                        cfes={cfes}
                        count={count}
                        page={page}
                        onPageChange={(event, nextPage) => {
                            handleSubmit((values) => onSubmit(values, nextPage))()
                        }}
                        rowsPerPage={rowsPerPage}
                        total={total}
                    />
                }
            </FormControl>
        </>
    )
}