import React, { useState } from 'react'

import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import {
    Grid,
    Table,
    Paper,
    TableRow,
    Checkbox,
    TableCell,
    TableBody,
    Typography,
    InputAdornment,
    TableContainer,
    TablePagination,
    DialogContentText,
} from '@mui/material'

import { TableHeadStyled, TableRowStyled, TableCellDesc, IconButtonStyled } from './style'
import TextField from '../TextField'
import EditButton from '../EditButton'
import { DialogContentStyled, DialogTitleStyled, DialogActionsStyled, DialogStyled, ApplyButton } from '../../pages/Produtos/style'
import CancelButton from '../CancelButton'
import { Controller, useForm } from 'react-hook-form'
import { updateProdutos } from '../../services/produtoService'
import { mediumGreen } from '../../variables'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Div } from '../../pages/_layouts/default/style'
import CircularProgressDisabled from '../CircularProgressDisabled'

const validationSchema = yup.object().shape({
    ncm: yup
        .string()
        .test(
            'ncm',
            "O campo NCM precisa ter 8 caracteres!",
            function (item) {
                if (item && item.trim().length < 8) {
                    return false
                }
                return (this.parent.ncm || this.parent.cfop || this.parent.csosn || this.parent.cst)
            }
        ),
    cfop: yup
        .string()
        .test(
            'cfop',
            "O campo CFOP precisa ter 4 caracteres!",
            function (item) {
                if (item && item.trim().length < 4) {
                    return false
                }
                return (this.parent.ncm || this.parent.cfop || this.parent.csosn || this.parent.cst)
            }
        ),
    csosn: yup
        .string()
        .test(
            'csosn',
            "O campo CSOSN precisa ter 3 caracteres!",
            function (item) {
                if (item && item.trim().length < 3) {
                    return false
                }
                return (this.parent.ncm || this.parent.cfop || this.parent.csosn || this.parent.cst)
            }
        ),
    cst: yup
        .string()
        .test(
            'cst',
            "O campo CST precisa ter 2 caracteres!",
            function (item) {
                if (item && item.trim().length < 2) {
                    return false
                }
                return (this.parent.ncm || this.parent.cfop || this.parent.csosn || this.parent.cst)
            }
        ),
})

export default function TableProduto({ produtos, onSelectAll, onSelect, checked, produtosSelecionados, idCliente, onRefresh, count, page, onPageChange, rowsPerPage }) {
    const [showDialog, setShowDialog] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = (event, reason) => {
        if (isLoading && reason === "backdropClick") {
            return false;
        }

        setShowDialog(false)
    }

    async function onSubmit(values) {
        setIsLoading(true)
        const { ncm, cfop, csosn, cst } = values
        const ids = produtosSelecionados.map(p => p.id)

        try {
            const response = await updateProdutos(idCliente, ids, ncm, cfop, csosn, cst)
            toast.success(response.data.message)
            setShowDialog(false)
            onRefresh()
        } catch (error) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const { control, handleSubmit, formState: { errors }, getValues, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ncm: "",
            cfop: "",
            csosn: "",
            cst: "",
        }
    })

    const handleCloseAlert = () => {
        setShowAlert(false)
    }

    return (
        <>
            {
                checked &&
                <EditButton
                    onClick={() => setShowDialog(true)}
                />
            }

            <DialogStyled
                maxWidth="md"
                open={showDialog}
                onClose={handleClose}
                disableEscapeKeyDown={isLoading}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <DialogTitleStyled>
                        Editar {produtosSelecionados.length} {produtosSelecionados.length > 1 ? 'produtos' : 'produto'}
                    </DialogTitleStyled>
                    <DialogContentStyled>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="ncm"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputRef={field.ref}
                                            error={!!errors.ncm}
                                            label="NCM"
                                            placeholder="Insira o NCM"
                                            inputProps={{
                                                maxLength: 8,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), ncm: '' })
                                                            }}
                                                            disabled={isLoading}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.ncm && !errors.cfop && !errors.csosn && !errors.cst && errors.ncm.message}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="cfop"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputRef={field.ref}
                                            label="CFOP"
                                            placeholder="Insira o CFOP"
                                            error={!!errors.cfop}
                                            inputProps={{
                                                maxLength: 4,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), cfop: '' })
                                                            }}
                                                            disabled={isLoading}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.cfop && !errors.ncm && !errors.csosn && !errors.cst && errors.cfop.message}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="csosn"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputRef={field.ref}
                                            error={!!errors.csosn}
                                            label="CSOSN"
                                            placeholder="Insira o CSOSN"
                                            inputProps={{
                                                maxLength: 3,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), csosn: '' })
                                                            }}
                                                            disabled={isLoading}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.csosn && !errors.ncm && !errors.cfop && !errors.cst && errors.csosn.message}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="cst"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputRef={field.ref}
                                            error={!!errors.cst}
                                            label="CST"
                                            placeholder="Insira o CST"
                                            inputProps={{
                                                maxLength: 2,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), cst: '' })
                                                            }}
                                                            disabled={isLoading}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.cst && !errors.ncm && !errors.cfop && !errors.csosn && errors.cst.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                sx={{
                                    color: 'red'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.ncm && !!errors.cfop && !!errors.csosn && !!errors.cst && 'Pelo menos um dos campos NCM, CFOP, CSOSN e CST é obrigatório!'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentStyled>
                    <DialogActionsStyled>
                        <CancelButton onClick={handleClose} disabled={isLoading}>
                            Cancelar
                        </CancelButton>
                        <ApplyButton
                            fullWidth={true}
                            type="submit"
                            label="Aplicar"
                            variant="contained"
                            startIcon={
                                isLoading ? <CircularProgressDisabled /> : null
                            }
                            disabled={isLoading}
                        />
                    </DialogActionsStyled>
                </form>
            </DialogStyled>

            <DialogStyled
                open={showAlert}
                onClose={handleClose}
            >
                <DialogTitleStyled>
                    <Div>
                        Atenção
                    </Div>
                </DialogTitleStyled>
                <DialogContentStyled>
                    <DialogContentText>
                        Ao mudar de página você perderá os itens selecionados.
                        Deseja continuar?
                    </DialogContentText>
                </DialogContentStyled>
                <DialogActionsStyled>
                    <ApplyButton
                        fullWidth={true}
                        type="submit"
                        label="Sim"
                        variant="contained"
                        onClick={() => onPageChange()}
                    />
                    <CancelButton
                        onClick={handleCloseAlert}
                    />
                </DialogActionsStyled>
            </DialogStyled>

            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: '10px',
                }}
            >
                <Table
                    aria-label="Tabela"
                >
                    <TableHeadStyled>
                        <TableRow>
                            <TablePagination
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={onPageChange}
                                rowsPerPageOptions={[rowsPerPage]}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`} itens`;
                                }}
                                sx={{
                                    '& .MuiTablePagination-displayedRows': {
                                        fontSize: '1em',
                                    }
                                }}
                            />
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    onChange={onSelectAll}
                                    sx={{
                                        color: `${mediumGreen}`,
                                        '&.Mui-checked': {
                                            color: `${mediumGreen}`,
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell align="left">
                                Descrição
                            </TableCell>
                            <TableCell align="left">
                                NCM
                            </TableCell>
                            <TableCell align="left">
                                CFOP
                            </TableCell>
                            <TableCell align="left">
                                CSOSN
                            </TableCell>
                            <TableCell align="left">
                                CST
                            </TableCell>
                            <TableCell align="left">
                                PIS
                            </TableCell>
                            <TableCell align="left">
                                Alíq. PIS
                            </TableCell>
                            <TableCell align="left">
                                COFINS
                            </TableCell>
                            <TableCell align="left">
                                Alíq. COFINS
                            </TableCell>
                        </TableRow>
                    </TableHeadStyled>
                    <TableBody>
                        {produtos.map((produto, index) => (
                            <TableRowStyled
                                key={produto.id}
                            >
                                <TableCell>
                                    <Checkbox
                                        checked={!!produto.checked}
                                        onChange={() => onSelect(index)}
                                        sx={{
                                            color: `${mediumGreen}`,
                                            '&.Mui-checked': {
                                                color: `${mediumGreen}`,
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCellDesc>
                                    {produto.descricao ? produto.descricao : 'N/A'}
                                </TableCellDesc>
                                <TableCell>
                                    {produto.ncm ? produto.ncm : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.cfop ? produto.cfop : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.csosn ? produto.csosn : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.cst ? produto.cst : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.pisSituacao ? produto.pisSituacao : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.pisAliquota ? `${produto.pisAliquota}%` : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.cofinsSituacao ? produto.cofinsSituacao : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {produto.cofinsAliquota ? `${produto.cofinsAliquota}%` : 'N/A'}
                                </TableCell>
                            </TableRowStyled>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}