import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import DefaultLayout from '../pages/_layouts/default'
import { Context } from '../contexts/auth'

export default function PrivateRoute({
    component: Component,
    ...rest
}) {
    const { loading, authenticated } = useContext(Context)

    return <Route
        {...rest}
        render={(props) => {
            if (loading) {
                return <h1>Loading...</h1>
            }

            if (!authenticated) {
                return <Redirect to="/login" />
            }

            return (
                <DefaultLayout>
                    <Component {...props} />
                </DefaultLayout>
            )
        }}
    />
}