import styled from 'styled-components'

import { Button } from '@mui/material'

import { button, mediumGray } from '../../variables';

const MyButton = styled(Button)`
    && { 
        color: ${mediumGray};
        height: 60px;
        width: 30%;
        border-radius: 10px;
        background-color: #EEF1F5;
        font-weight: 700;
        font-size: ${button};
        box-shadow: none;
    }

    && {
        :hover {
            background-color: #E0E3E7;
            color: ${mediumGray};
            filter: brightness(1);
            transition: .5s;
            box-shadow: none;
        }
    }
`;

export { MyButton }