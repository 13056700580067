import styled from 'styled-components'

import { lightGray, mediumGray, paragraph, subtitle } from '../../variables';

const Div = styled.div`
    display: flex;
    border-bottom: 1px solid ${lightGray};
`;

const Title = styled.h2`
    font-size: ${subtitle};
    font-weight: 700;
    text-align: left;
    color: ${mediumGray};
`;

const SubTitle = styled.h3`
    font-size: ${paragraph};
    font-weight: 400;
    color: #909698;
    padding: 1em 0 0 1em;
`;

export { Div, Title, SubTitle }