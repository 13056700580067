import * as React from "react";
import {
    DialogContentText,
} from "@mui/material";

import { 
    ApplyButton,
    DialogStyled, 
    DialogTitleStyled, 
    DialogActionsStyled, 
    DialogContentStyled, 
} from '../../pages/Produtos/style'
import { Div } from '../../pages/_layouts/default/style'
import CancelButton from '../CancelButton'

export default function ConfirmationDialog({
    open,
    onSubmit,
    onClose
}) {
    return (
        <DialogStyled
            open={open}
        >
            <DialogTitleStyled>
                <Div>
                    Atenção
                </Div>
            </DialogTitleStyled>
            <DialogContentStyled>
                <DialogContentText>
                    Ao mudar de página você perderá os itens selecionados. <br /> Tem certeza que deseja continuar?
                </DialogContentText>
            </DialogContentStyled>
            <DialogActionsStyled>
                <CancelButton
                    onClick={onClose}
                />
                <ApplyButton
                    fullWidth={true}
                    type="submit"
                    label="Sim"
                    variant="contained"
                    onClick={onSubmit}
                />
            </DialogActionsStyled>
        </DialogStyled>
    );
};
