import { useState, useEffect } from 'react'

import api from '../../services/api'
import history from '../../services/history'

export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false)
    const [authenticating, setAuthenticating] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [user, setUser] = useState(null)

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken')

        if (accessToken) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`
            const _user = JSON.parse(localStorage.getItem('user'))
            setUser(_user)
            setAuthenticated(true)
        }

        setLoading(false)
    }, [])

    async function handleLogin(username, password) {
        setError(null)
        setAuthenticating(true)
        const data = {
            username: username,
            password: password,
        }

        try {
            const response = await api.post('/api/auth/signin', data)

            localStorage.setItem('accessToken', JSON.stringify(response.data.accessToken))
            api.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`
            const { userName, logo } = response.data
            const _user = { userName, logo }
            localStorage.setItem('user', JSON.stringify(_user))
            setUser(_user)
            setAuthenticated(true)
            history.push('/produtos')
        } catch (error) {
            setError(error.response?.data?.message || error.message)
        } finally {
            setAuthenticating(false)
        }
    }

    function handleLogout() {
        setAuthenticated(false)
        setUser(null)
        localStorage.removeItem('accessToken')
        api.defaults.headers.Authorization = undefined
        history.push('/login')
    }

    return { authenticating, authenticated, loading, handleLogin, handleLogout, error, user }
}