import React from 'react'

import {
    Table,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    FormControl,
    TableFooter,
    TableContainer,
    TablePagination,
} from '@mui/material'

import { TableHeadStyled, TableRowStyled } from './style'
import dateFormat from '../../utils/dateFormat'
import toCurrency from '../../utils/toCurrency'
import { mediumGray, paragraph } from '../../variables'

export default function TableEstabelecimento({ nfes, count, page, onPageChange, rowsPerPage, total }) {
    return (
        <FormControl>
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: '10px'
                }}
            >
                <Table
                    aria-label="Tabela"
                >
                    <TableHeadStyled>
                        <TableRow>
                            <TablePagination
                                count={count}
                                page={page}
                                onPageChange={onPageChange}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[rowsPerPage]}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`} itens`;
                                }}
                                sx={{
                                    '& .MuiTablePagination-displayedRows': {
                                        fontSize: '1em',
                                    }
                                }}
                            />
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                Número
                            </TableCell>
                            <TableCell align="left">
                                Evento
                            </TableCell>
                            <TableCell align="left">
                                Protocolo
                            </TableCell>
                            <TableCell align="left">
                                Data/Hora
                            </TableCell>
                            <TableCell align="right">
                                Valor
                            </TableCell>
                        </TableRow>
                    </TableHeadStyled>
                    <TableBody>
                        {nfes.map(nfe => (
                            <TableRowStyled
                                key={nfe.protocolo}
                            >
                                <TableCell>
                                    {nfe.numero ? nfe.numero : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {nfe.evento ? nfe.evento : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {nfe.protocolo ? nfe.protocolo : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {dateFormat(nfe.dataHora, true, true, true)}
                                </TableCell>
                                <TableCell align="right">
                                    {toCurrency('BRL', nfe.valor)}
                                </TableCell>
                            </TableRowStyled>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow
                            sx={{
                                borderTop: '1px solid rgba(224, 224, 224, 1)',
                            }}
                        >
                            <TableCell
                                colSpan={4}
                                align="right"
                                sx={{
                                    fontWeight: 700,
                                    color: `${mediumGray}`,
                                    fontSize: `${paragraph}`
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={{
                                    fontSize: `${paragraph}`
                                }}
                            >
                                {toCurrency('BRL', Number(total))}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </FormControl>
    )
}