import React from 'react'

import { Div, Title, SubTitle } from './style'

export default function TitleCard({pageName, description, ...rest}) {
    return (
        <Div>
            <Title
                {...rest}
            >
                {pageName}
            </Title>
            <SubTitle
                {...rest}
            >
                {description}
            </SubTitle>
        </Div>
    )
}