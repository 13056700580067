// Colors

const white = '#fff'
const lightGreen = '#BAC108' 
const mediumGreen = '#888F08'
const darkGreen = '#717806'
const lightGray = '#CCD4D9'
const mediumGray = '#575E62'
const darkGray = '#404648'

// Typography

const title = '3em'
const subtitle = '2em'
const paragraph = '1em'
const button = '.9em'

export {
    white,
    title,
    button,
    darkGray,
    subtitle,
    paragraph,
    darkGreen,
    lightGray,
    mediumGray,
    lightGreen,
    mediumGreen, 
}