import api from './api'

export const getProdutos = async (id, descricao, ncm, cfop, csosn, cst, page) => {
    return await api.get('/api/produtos',
        {
            params: { descricao, ncm, cfop, csosn, cst, page },
            headers: { 'X-TenantId': id }
        }
    )
}

export const updateProdutos = async (id, produtoIds, ncm, cfop, csosn, cst) => {
    var data = { ids: produtoIds }

    if (ncm !== '') {
        data = { ...data, ncm }
    }

    if (cfop !== '') {
        data = { ...data, cfop }
    }

    if (csosn !== '') {
        data = { ...data, csosn }
    }

    if (cst !== '') {
        data = { ...data, cst }
    }

    return await api.put('/api/produtos',
        data,
        {
            headers: { 'X-TenantId': id }
        }
    )
}