import axios from 'axios';
import EventPublisher from './eventPublisher';

const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8080'
  : 'https://fiscalcloud-api.origam.com.br'

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
})

api.interceptors.response.use((response) => {
  return Promise.resolve(response);
}, async function (error) {
  if (error.response.status === 401) {
    EventPublisher.instance.publish('ACCESS_TOKEN_EXPIRED');
    return Promise.reject({ message: 'Sua sessão expirou. Para continuar usando, é só logar de novo.' });
  }
  return Promise.reject(error);
})

export default api