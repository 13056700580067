import React from 'react'
import Login from '../pages/Login'
import Dashboard from '../pages/Produtos'
import Cfe from '../pages/Cfe'
import { Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Route from './Route'
import Estabelecimento from '../pages/Estabelecimento'
import Fornecedor from '../pages/Fornecedor'
import Nfce from '../pages/Nfce'

export default function Routes() {
    return (
        <Switch>
            <Route exact component={Login} path="/login" />
            <PrivateRoute exact component={Dashboard} path="/produtos" />

            <PrivateRoute exact component={Cfe} path="/cfe" />
            <PrivateRoute exact component={Nfce} path="/nfce" />
            <PrivateRoute exact component={Estabelecimento} path="/nfe-estabelecimento" />
            <PrivateRoute exact component={Fornecedor} path="/nfe-fornecedor" />

            <Redirect from="/" to="/produtos" />
        </Switch>
    )
}