import styled from 'styled-components';

import { Button } from '@mui/material';

import { button, darkGreen, mediumGreen, white } from '../../variables'

const MyButton = styled(Button)`
    && {
        color: ${white};
        background-color: ${mediumGreen};
        height: 60px;
        width: 100%;
        font-size: ${button};
        font-weight: 700;
        letter-spacing: 1px;
        border-radius: 10px;

        :hover {
            background-color: ${darkGreen};
            transition: .5s;
        }

        :disabled {
            color: ${white};
            background-color: ${mediumGreen}88;
        }
    }

`;

export { MyButton }