import { Card } from '@mui/material'
import DefaultButton from '../../components/DefaultButton'

import styled from 'styled-components'
import { mediumGreen } from '../../variables'

const CardStyled = styled(Card)`
    && {
        border-radius: 10px;
        padding: 1em;
        margin-bottom: 2em;
    }
`;

const DownloadButton = styled(DefaultButton)`
    && {
        :disabled {
            background-color: ${mediumGreen}88;
        }
    }
`;

export { CardStyled, DownloadButton }