export default class EventPublisher {
    static instance;
    listeners = {};

    constructor(validEvents) {
        validEvents.forEach(ve => {
            this.listeners[ve] = []
        })
    }

    subscribe(event, listener) {
        if (!this.listeners[event]) throw new Error('Invalid event')
        this.listeners[event].push(listener)
        return () => {
            this.listeners[event].remove(listener)
        }
    }

    publish(event) {
        this.listeners[event].forEach(l => l())
    }
}