import styled from 'styled-components'

import { Select } from '@mui/material'

const SelectStyled = styled(Select)`
    && {
        width: 100%;
        height: 60px;

        .MuiSelect-select {
            padding: 0;
            padding: 1em;
        }
    }
`;

export { SelectStyled }