import api from './api'
import { format } from 'date-fns'

export const downloadNfces = async (id, dateOf, dateUntil) => {
    const dataDe = format(dateOf, 'yyyy-MM-dd')
    const dataAte = format(dateUntil, 'yyyy-MM-dd')
    return await api.get('/api/nfce/download',
        {
            responseType: 'blob',
            params: { dataDe, dataAte },
            headers: { 'X-TenantId': id }
        })
}

export const getNfces = async (id, dateOf, dateUntil, page) => {
    const dataDe = format(dateOf, 'yyyy-MM-dd')
    const dataAte = format(dateUntil, 'yyyy-MM-dd')
    return await api.get('/api/nfce',
        {
            params: { dataDe, dataAte, page },
            headers: { 'X-TenantId': id }
        })
}