import styled from "styled-components"

import { Typography } from "@mui/material";

import { darkGray, darkGreen, mediumGray, paragraph, subtitle, title } from '../../variables'

// Form

const MyTitle = styled(Typography)`
    && {
        font-size: ${title};
        font-weight: 800;
        text-align: center;
        margin: 0;
        color: ${darkGray};
    }
`;

const MySubtitle = styled(Typography)`
    && {
        font-size: ${subtitle};
        color: ${mediumGray};
        font-weight: 800;
        margin: 2em 0 1em 0;
    }
`;

const MyCopyright = styled.p`
    font-size: ${paragraph};
    color: ${darkGreen};
    font-weight: 700;
    text-align: center;
    margin-top: 4em;
    margin-bottom: 0;
    
`;

const MyImg = styled.img` 
        width: 70%; 
`;

export {
        MyImg,
        MyTitle,
        MySubtitle,
        MyCopyright,
}
