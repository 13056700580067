import styled from 'styled-components'

import { 
    Avatar,
    Dialog,
    Button,
    Toolbar,
    MenuList, 
    DialogTitle,
    DialogContent,
    DialogActions,
    Drawer as MUIDrawer, 
} from '@mui/material'

import {
    white,
    darkGray,
    lightGray,
    darkGreen,
    lightGreen,
    mediumGreen,
    mediumGray,
    paragraph,
    button,
    subtitle,
} from '../../../variables'

// Drawer

const Drawer = styled(MUIDrawer)`
    && {
        width: 350px;

        .MuiPaper-root {
            background-color: ${darkGray};
            border: none;
            box-shadow: 1px 4px 5px rgba(0, 0, 0, .5);
        }
    }
`;

const MenuListStyle = styled(MenuList)`
    && {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: ${darkGray};
        height: 100%;
        width: 350px;
        color: ${white};

        a:hover {
            background-color: #3A4042;
            color: ${white};
        }

        a {
            padding: 1em 2em 1em 2em;
            color: white;
            text-decoration: none;
        }
    }
`;

const ToolbarStyled = styled(Toolbar)`
    && {
        color: ${white};
        box-sizing: border-box;
        border-bottom: 1px solid ${mediumGray}88;
        
        :last-child {
            padding: 0;
        }
    }
`;

const ToolbarStyled2 = styled(Toolbar)`
    border-top: 1px solid ${mediumGray}88;

    :last-child {
        padding: 0;
    }
`;

const AvatarStyled = styled(Avatar)`
    && {
        margin-right: 10px;
    }
`;

const DivToolbar = styled.div`
    padding: 1em 2em;
    font-size: ${paragraph};
`;

// Button

const ConfirmButton = styled(Button)`
    && {
        background-color: ${mediumGreen};
        width: 30%;
        height: 60px!important;
        font-weight: 700;
        margin-left: 0!important;
        padding: .8em;
        height: 100%;
        border-radius: 10px;
        color: ${white};
        font-size: ${button};

        :hover {
            background-color: ${darkGreen};
        }
    }
`;

const IconButton = styled(Button)`
    && {
        color: ${white};
        font-size: ${paragraph};

        :hover {
            background-color: transparent;
            color: ${lightGreen};
        }
    }
`;

// Dialog

const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DialogStyled = styled(Dialog)`
    && {
        .MuiPaper-root {
            display: flex;
            width: 500px;
            padding: 2em;
            border-radius: 10px;
        }
    }
`;

const DialogContentStyled = styled(DialogContent)`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        p {
            font-size: ${paragraph};
            color: ${mediumGray};
            margin: 0;
            padding: 1em 0;
        }
    }
`;

const DialogActionsStyled = styled(DialogActions)`
    && {
        justify-content: space-evenly;
        padding: 0;
    }
`;

const DialogTitleStyled = styled(DialogTitle)`
    && {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        border-bottom: 1px solid ${lightGray};
        font-size: ${subtitle};
        font-weight: 700;
        color: ${darkGray};
    }
`;

export {
    Div,
    Drawer,
    DivToolbar,
    IconButton,
    AvatarStyled,
    DialogStyled,
    ConfirmButton,
    ToolbarStyled,
    MenuListStyle,
    ToolbarStyled2,
    DialogTitleStyled,
    DialogContentStyled,
    DialogActionsStyled,
}
