import React, { useState, useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDidUpdate } from "rooks";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { toast } from 'react-toastify';

import * as yup from "yup"
import * as masks from "../../utils/masks";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, InputAdornment } from "@mui/material"
import { Grid } from "@mui/material"

import Button from "../../components/DefaultButton"
import { Context } from '../../contexts/auth'
import TextField from "../../components/TextField"
import { MyImg, MyTitle, MySubtitle, MyCopyright } from "./style"
import logotipo from '../../assets/images/logotipo.svg'
import bgImage from '../../assets/images/bg-image.png'
import CircularProgressDisabled from '../../components/CircularProgressDisabled'
import { white } from "../../variables";

const validationSchema = yup.object({
    username: yup
        .string()
        .transform(masks.cnpjMask.transform)
        .required("Informe o CNPJ!")
        .test("validateCnpj", "CNPJ inválido", (value) => {
            return isValidCNPJ(value);
        }),
    password: yup.string().required("Informe a senha!")
})

export default function Login() {
    const [showPassword, setShowPassword] = useState(false)
    const { authenticating, handleLogin, error } = useContext(Context)

    const { control, handleSubmit, formState: { errors }, register } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            username: "",
            password: "",
        }
    })

    async function onSubmit(values) {
        const { username, password } = values
        handleLogin(username, password)
    }

    useDidUpdate(() => {
        if (error) {
            toast.error(error)
        }
    }, [error])

    return (
        <>
            <Grid
                container
                component='main'
                sx={{
                    height: '100vh',
                    backgroundColor: `${white}`,
                }}
            >
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={6}
                    xl={7}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: `url(${bgImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <MyImg src={logotipo}></MyImg>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    xl={5}
                    sx={{
                        padding: '4em',
                        alignSelf: 'center',
                    }}
                >
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <MyTitle
                            variant="h1"
                            component="h2"
                        >
                            Ori Cloud
                        </MyTitle>

                        <MySubtitle
                            variant="h2"
                            component="h3"
                        >
                            Log In
                        </MySubtitle>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                        >
                            <Controller
                                control={control}
                                name="username"
                                inputRef={register()}
                                render={({ field }) => (
                                    <TextField
                                        value={field.value}
                                        onChange={(e) => {
                                            e.persist();
                                            masks.cnpjMask.onChange(e);
                                            field.onChange(e);
                                        }}
                                        autoFocus
                                        inputRef={field.ref}
                                        placeholder="Insira o CNPJ"
                                        label="CNPJ"
                                        error={!!errors.username}
                                        disabled={authenticating}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                paddingTop: '0px!important',
                                marginBottom: '1em',
                                color: 'red'
                            }}
                        >
                            <span>
                                {errors.username?.message}
                            </span>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                        >
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        placeholder="Insira a senha"
                                        type={showPassword ? "text" : "password"}
                                        label="Senha"
                                        error={!!errors.password}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end">
                                                    <IconButton
                                                        onClick={e => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        disabled={authenticating}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                paddingTop: '0px!important',
                                marginBottom: '1em',
                                color: 'red'
                            }}
                        >
                            <span>
                                {errors.password?.message}
                            </span>
                        </Grid>
                        <Button
                            label="Entrar"
                            type="submit"
                            startIcon={
                                authenticating ? <CircularProgressDisabled /> : null
                            }
                            disabled={authenticating}
                        />
                    </form>
                    <MyCopyright>
                        <a
                            href="https://www.origam.com.br/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            2021 © Origam Sistemas
                        </a>
                    </MyCopyright>
                </Grid>
            </Grid>
        </>
    )
}