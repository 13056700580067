import styled from 'styled-components'

import { mediumGray } from '../../variables'

const Span = styled.span`
    font-weight: 700;
    color: ${mediumGray}99;
`;

const Div = styled.div`
    display: flex;
    align-items: center;

    input {
        height: 60px;
        box-sizing: border-box;
    }
`;

export { Div, Span }