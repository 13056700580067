import React from 'react'
import { ptBR } from "date-fns/locale";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { Div, Span } from './style'
import { Label } from '../TextField/style'
import { Controller } from 'react-hook-form'

export default function MyDatePicker({ control, errors, ...rest }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Div>
                    <Grid
                        item
                        sm={5}
                    >
                        <Label>Período</Label>
                        <Controller
                            control={control}
                            name="dataDe"
                            render={({ field }) => (
                                <DatePicker
                                    value={field.value}
                                    onChange={field.onChange}
                                    inputRef={field.ref}
                                    fullWidth={true}
                                    error={errors}
                                    inputFormat="dd/MM/yyyy"
                                    {...rest}
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                        sx={{
                            height: '40px',
                            textAlign: 'center',
                            alignSelf: 'flex-end'
                        }}
                    >
                        <Span>até</Span>
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        sx={{
                            alignSelf: 'flex-end'
                        }}
                    >
                        <Controller
                            control={control}
                            name="dataAte"
                            render={({ field }) => (
                                <DatePicker
                                    value={field.value}
                                    onChange={field.onChange}
                                    inputRef={field.ref}
                                    fullWidth={true}
                                    error={errors}
                                    inputFormat="dd/MM/yyyy"
                                    {...rest}
                                />
                            )}
                        />
                    </Grid>
                </Div>
            </Grid>
        </LocalizationProvider>
    )
}