import React from 'react'
import { HiOutlinePencil } from 'react-icons/hi'

import { Grid } from '@mui/material'

import DefaultButton from '../DefaultButton'

export default function EditButton({ ...rest }) {

    return (
        <Grid
            container
        >
            <Grid
                item
                xs={2}
                md={2}
                mb={2}
            >
                <DefaultButton
                    label="Editar"
                    startIcon={<HiOutlinePencil />}
                    { ...rest }
                />
            </Grid>
        </Grid>
    )
}