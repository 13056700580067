import React, { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'

import { SelectStyled } from './style'
import { Div, Label } from '../TextField/style'
import { getClientes } from '../../services/clienteService'

export default function Select({ label, nuvemOnly = false, ...rest }) {
    const [clientes, setClientes] = useState([])

    useEffect(() => {
        async function fetchCustomers() {
            const response = await getClientes(nuvemOnly)
            setClientes(response.data)
        }

        fetchCustomers()
    }, [nuvemOnly])

    return (
        <Div>
            <Label>{label}</Label>
            <SelectStyled
                fullWidth={true}
                {...rest}

            >
                <MenuItem
                    value=""
                    disabled
                >
                    Selecione o cliente
                </MenuItem>
                {clientes.map(cliente => (
                    <MenuItem
                        key={cliente.id}
                        value={cliente.id}
                    >
                        {cliente.razaoSocial}
                    </MenuItem>
                ))}
            </SelectStyled>
        </Div>
    )
}