import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { RiLogoutBoxRLine } from "react-icons/ri"
import { Context } from '../../../contexts/auth'

import { Box, Typography, DialogContentText } from "@mui/material"

import CancelButton from '../../../components/CancelButton'
import {
    Div,
    Drawer,
    IconButton,
    DivToolbar,
    AvatarStyled,
    DialogStyled,
    ToolbarStyled,
    ConfirmButton,
    MenuListStyle,
    ToolbarStyled2,
    DialogTitleStyled,
    DialogContentStyled,
    DialogActionsStyled,
} from './style'

export default function DefaultLayout({ children }) {
    const { handleLogout, user } = useContext(Context)
    const [openDialog, setOpenDialog] = useState(false)

    const handleClickOpen = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    return (
        <>
            <DialogStyled
                open={openDialog}
                onClose={handleClose}
            >
                <DialogTitleStyled>
                    <Div>
                        Sair
                    </Div>
                </DialogTitleStyled>
                <DialogContentStyled>
                    <DialogContentText>
                        Tem certeza que deseja sair?
                    </DialogContentText>
                </DialogContentStyled>
                <DialogActionsStyled>
                    <CancelButton
                        onClick={handleClose}
                    />
                    <ConfirmButton
                        fullWidth={true}
                        variant="contained"
                        onClick={handleLogout}
                    >
                        Sair
                    </ConfirmButton>
                </DialogActionsStyled>
            </DialogStyled>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Drawer
                    variant='permanent'
                >
                    <DivToolbar>
                        <ToolbarStyled>
                            <AvatarStyled
                                alt="Logo da empresa"
                                src={user.logo}
                            />
                            <Typography
                                sx={{
                                    fontSize: '1em',
                                    width: '230px',
                                }}
                            >
                                {user.userName}
                            </Typography>
                        </ToolbarStyled>
                    </DivToolbar>
                    <MenuListStyle>
                        <NavLink
                            to="/produtos"
                            activeClassName="selected"
                        >
                            Produtos
                        </NavLink>
                        <NavLink
                            to="/cfe"
                            activeClassName="selected"
                        >
                            CF-e
                        </NavLink>
                        <NavLink
                            to="/nfce"
                            activeClassName="selected"
                        >
                            NFC-e
                        </NavLink>
                        <NavLink
                            to="/nfe-estabelecimento"
                            activeClassName="selected"
                        >
                            NF-e Estabelecimento
                        </NavLink>
                        <NavLink
                            to="/nfe-fornecedor"
                            activeClassName="selected"
                        >
                            NF-e Fornecedor
                        </NavLink>
                    </MenuListStyle>
                    <DivToolbar>
                        <ToolbarStyled2>
                            <IconButton
                                startIcon={<RiLogoutBoxRLine />}
                                onClick={handleClickOpen}
                                sx={{
                                    padding: 0,
                                    letterSpacing: '1px'
                                }}
                            >
                                Sair
                            </IconButton>
                        </ToolbarStyled2>
                    </DivToolbar>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100%',
                        margin: '2em',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </>
    )
}