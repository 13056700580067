import styled, { keyframes } from 'styled-components'
import { darkGreen, lightGreen } from '../../variables';

const spinning = keyframes`
    from { transform: rotateZ(360deg); }
    to { transform: rotateZ(0); }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1em 0;
`;

const Loader = styled.div`
    position: relative;
    background-color: ${lightGreen};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: conic-gradient(${darkGreen}, transparent);
    animation: 1.5s ${spinning} infinite linear both;

    :before, 
    :after {
        content: '';
        position: absolute;
        border-radius: 50%;
    }

    :before {
        width: 12px;
        height: 12px;
        top: 3.9px;
        left: 3.9px;
        background-color: #bfc37b;
    }

    :after {
        width: 4px;
        height: 4px;
        background-color: ${darkGreen};
        top: 0;
        left: 8px;
    }
`;

export { Container, Loader }