export default function dateFormat(date, hour = false, minute = false, second = false) {
    if (!date) {
        return ''
    }

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    if (hour) options.hour = 'numeric'
    if (minute) options.minute = 'numeric'
    if (second) options.second = 'numeric'

    return Intl.DateTimeFormat('pt-BR', options).format(Date.parse(date))
}