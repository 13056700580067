import styled, { keyframes } from 'styled-components'
import { lightGreen } from '../../variables';

const spinning = keyframes`
    from { transform: rotateZ(360deg); }
    to { transform: rotateZ(0); }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1em 0;
`;

const Loader = styled.div`
    position: relative;
    background-color: ${lightGreen};
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: conic-gradient(${lightGreen}, transparent);
    animation: 1.5s ${spinning} infinite linear both;

    :before, 
    :after {
        content: '';
        position: absolute;
        border-radius: 50%;
    }

    :before {
        width: 80px;
        height: 80px;
        top: 30px;
        left: 30px;
        background-color: #eee;
    }

    :after {
        width: 30px;
        height: 30px;
        background-color: ${lightGreen};
        top: 0px;
        left: 54px;
    }
`;

export { Container, Loader }