import React, { useState } from 'react'
import { useConfirmation } from '../../services/confirmationService';
import { Controller, useForm } from 'react-hook-form'
import { useDidUpdate } from "rooks";
import {
    RiFilterOffLine,
    RiFilterLine,
    RiSearchLine,
} from "react-icons/ri"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { toast } from 'react-toastify';

import {
    Box,
    Grid,
    Badge,
    FormControl,
    CardContent,
    Typography,
    InputAdornment,
} from '@mui/material'

import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'

import { CardStyled } from '../Cfe/style'
import TitleCard from '../../components/Title'
import SelectStyled from '../../components/Select'
import TextField from '../../components/TextField'
import Button from '../../components/DefaultButton'
import CancelButton from '../../components/CancelButton'
import { getProdutos } from '../../services/produtoService'
import TableStyled from '../../components/Tables/TableProduto'
import {
    ApplyButton,
    DialogStyled,
    ClearFilters,
    DialogTitleStyled,
    DialogContentStyled,
    DialogActionsStyled,
} from './style'
import CircularProgress from '../../components/CircularProgress'
import { mediumGray } from '../../variables';
import { Title } from '../../components/Title/style';
import { IconButtonStyled } from '../../components/Tables/style'

const validationSchema = yup.object({
    clienteId: yup.string().required("Selecione o cliente!"),
    ncm: yup.string().test('ncm', "O campo NCM precisa ter 8 caracteres!",
        function (ncm) {
            if (ncm && ncm.trim().length < 8) {
                return false
            }
            return true
        }
    ),
    cfop: yup.string().test('cfop', "O campo CFOP precisa ter 4 caracteres!",
        function (cfop) {
            if (cfop && cfop.trim().length < 4) {
                return false
            }
            return true
        }
    ),
    csosn: yup.string().test('csosn', "O campo CSOSN precisa ter 3 caracteres!",
        function (csosn) {
            if (csosn && csosn.trim().length < 3) {
                return false
            }
            return true
        }
    ),
    cst: yup.string().test('cst', "O campo CST precisa ter 2 caracteres!",
        function (cst) {
            if (cst && cst.trim().length < 2) {
                return false
            }
            return true
        }
    ),
}).required()

export default function Produtos() {
    const [showDialog, setShowDialog] = useState(false)
    const [produtos, setProdutos] = useState([])
    const [produtosSelecionados, setProdutosSelecionados] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filterSize, setFilterSize] = useState(0)
    const [checked, setChecked] = useState(false)
    const [anySelected, setAnySelected] = useState(false)
    const confirm = useConfirmation()

    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(0)

    const { control, handleSubmit, formState: { errors }, reset, getValues, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            clienteId: "",
            descricao: "",
            ncm: "",
            cfop: "",
            csosn: "",
            cst: "",
        }
    })

    const watchFields = watch(["ncm", "cfop", "csosn", "cst"]);

    function submitDialog(e) {
        e.preventDefault()
        handleSubmit((values) => onSubmit(values))()
    }

    async function onSubmit(values, page = 0) {
        setShowDialog(false)
        setIsLoading(true)
        setProdutos([])
        try {
            const { clienteId, descricao, ncm, cfop, csosn, cst } = values
            const response = await getProdutos(clienteId, descricao, ncm, cfop, csosn, cst, page)
            const _produtos = response.data.content
            setPage(page);
            setProdutos(_produtos)
            setProdutosSelecionados([])
            setChecked(false)
            setCount(response.data.totalElements)
            setRowsPerPage(response.data.size)
        } catch (error) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickOpen = () => {
        setShowDialog(true)
    }

    const handleClose = () => {
        setShowDialog(false)
    }

    useDidUpdate(() => {
        const subscription = watch((value) => {
            const { ncm, cfop, csosn, cst } = value
            var size = 0

            if (ncm !== "") size += 1
            if (cfop !== "") size += 1
            if (csosn !== "") size += 1
            if (cst !== "") size += 1

            setFilterSize(size)
        });
        return () => subscription.unsubscribe();
    }, [watch, watchFields]);

    useDidUpdate(() => {
        setAnySelected(checked || produtos.filter(p => p.checked).length > 0)
    }, [produtos, checked]);

    return (
        <>
            <FormControl
                onSubmit={handleSubmit((values) => onSubmit(values))}
                sx={{
                    width: '100%',
                }}
            >
                <CardStyled>
                    <CardContent>
                        <Box
                            component="form"
                        >
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TitleCard
                                    pageName="Produtos"
                                />
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        padding: '1em 0'
                                    }}
                                >
                                    <Grid
                                        item
                                        sm={4}
                                    >
                                        <Controller
                                            control={control}
                                            name="clienteId"
                                            render={({ field }) => (
                                                <SelectStyled
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        setProdutos([])
                                                        setProdutosSelecionados([])
                                                        field.onChange(e)
                                                        reset({ ...getValues(), descricao: '', ncm: '', csosn: '', cfop: '', cst: '' })
                                                    }}
                                                    inputRef={field.ref}
                                                    displayEmpty
                                                    label="Clientes"
                                                    nuvemOnly
                                                    error={!!errors.clienteId}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                    >
                                        <Controller
                                            control={control}
                                            name="descricao"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    inputRef={field.ref}
                                                    placeholder="Insira o nome do produto"
                                                    type="text"
                                                    label="Produto"
                                                    id="clearText"
                                                    sx={{
                                                        margin: '0!important',
                                                    }}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment
                                                                position="end"
                                                            >
                                                                <IconButtonStyled
                                                                    onClick={() => {
                                                                        reset({ ...getValues(), descricao: '' })
                                                                    }}
                                                                >
                                                                    <IoIosCloseCircleOutline />
                                                                </IconButtonStyled>
                                                            </InputAdornment>
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={2}
                                        sx={{
                                            alignSelf: 'flex-end'
                                        }}
                                    >
                                        <Badge
                                            badgeContent={filterSize}
                                            color="primary"
                                            sx={{
                                                width: '100%',
                                                '& .MuiBadge-badge': {
                                                    background: `${mediumGray}`,
                                                    width: '25px',
                                                    height: '25px',
                                                    borderRadius: '100%',
                                                    fontSize: '1em',
                                                    fontWeight: '400'
                                                }
                                            }}
                                        >
                                            <Button
                                                label="Filtros"
                                                onClick={handleClickOpen}
                                                startIcon={
                                                    <RiFilterLine />
                                                }
                                            />
                                        </Badge>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={2}
                                        sx={{
                                            alignSelf: 'flex-end'
                                        }}
                                    >
                                        <ClearFilters
                                            label="Limpar filtros"
                                            startIcon={
                                                <RiFilterOffLine />
                                            }
                                            onClick={() => {
                                                reset({ ...getValues(), ncm: '', cfop: '', csosn: '', cst: '' })
                                                handleSubmit((values) => onSubmit(values))()
                                            }}
                                            disabled={filterSize === 0 ? true : false}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        sx={{
                                            paddingTop: '0px!important',
                                            color: 'red'
                                        }}
                                    >
                                        <span>
                                            {errors.clienteId?.message}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                >
                                    <Button
                                        type="submit"
                                        label="Buscar"
                                        startIcon={
                                            <RiSearchLine />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </CardStyled>

                {
                    isLoading && (<CircularProgress />)
                }


            </FormControl>
            {
                produtos.length > 0 && !isLoading &&
                <TableStyled
                    produtos={produtos}
                    checked={anySelected}
                    produtosSelecionados={produtosSelecionados}
                    idCliente={getValues('clienteId')}
                    onRefresh={handleSubmit((values) => onSubmit(values, page))}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onSelectAll={() => {
                        const _produtos = produtos.map(p => ({ ...p, checked: !checked }))
                        const _produtosSelecionados = checked ? [] : [..._produtos]

                        setProdutos(_produtos)
                        setProdutosSelecionados(_produtosSelecionados)
                        setChecked(!checked)
                    }}
                    onSelect={(index) => {
                        const _produtos = [...produtos]
                        const _produtosSelecionados = [...produtosSelecionados]

                        var produto = _produtos[index]
                        produto.checked = !produto.checked

                        if (produto.checked) {
                            _produtosSelecionados.push(produto)
                        } else {
                            const idx = _produtosSelecionados.indexOf(p => p.id = produto.id)
                            _produtosSelecionados.splice(idx)
                        }

                        setProdutos(_produtos)
                        setProdutosSelecionados(_produtosSelecionados)
                    }}
                    onPageChange={(event, nextPage) => {
                        if (produtosSelecionados.length > 0) {
                            confirm({
                                catchOnCancel: true,
                                title: "Atenção",
                                description: "Ao mudar de página você perderá os itens selecionados. Deseja continuar?"
                            })
                                .then(() => {
                                    handleSubmit((values) => onSubmit(values, nextPage))()
                                })
                                .catch(() => { })
                        } else {
                            handleSubmit((values) => onSubmit(values, nextPage))()
                        }
                    }}
                />
            }
            {
                produtos.length === 0 &&
                <Grid
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {
                            !isLoading ?
                                <Title>
                                    Nenhum registro encontrado...
                                </Title> :
                                null
                        }
                    </Grid>
                </Grid>
            }
            <DialogStyled
                maxWidth="md"
                open={showDialog}
                onClose={handleClose}
            >
                <form
                    onSubmit={submitDialog}
                >
                    <DialogTitleStyled>
                        Filtros
                    </DialogTitleStyled>
                    <DialogContentStyled>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="ncm"
                                    render={({ field }) => (
                                        <TextField
                                            onChange={field.onChange}
                                            value={field.value}
                                            inputRef={field.ref}
                                            label="NCM"
                                            placeholder="Insira o NCM"
                                            error={!!errors.ncm}
                                            inputProps={{
                                                maxLength: 8,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), ncm: '' })
                                                            }}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.ncm && errors.ncm.message}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="cfop"
                                    render={({ field }) => (
                                        <TextField
                                            onChange={field.onChange}
                                            value={field.value}
                                            inputRef={field.ref}
                                            label="CFOP"
                                            placeholder="Insira o CFOP"
                                            error={!!errors.cfop}
                                            inputProps={{
                                                maxLength: 4,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), cfop: '' })
                                                            }}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.cfop && errors.cfop.message}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="csosn"
                                    render={({ field }) => (
                                        <TextField
                                            onChange={field.onChange}
                                            value={field.value}
                                            inputRef={field.ref}
                                            label="CSOSN"
                                            placeholder="Insira o CSOSN"
                                            error={!!errors.csosn}
                                            inputProps={{
                                                maxLength: 3,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), csosn: '' })
                                                            }}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.csosn && errors.csosn.message}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                sm={3}
                            >
                                <Controller
                                    control={control}
                                    name="cst"
                                    render={({ field }) => (
                                        <TextField
                                            onChange={field.onChange}
                                            value={field.value}
                                            inputRef={field.ref}
                                            label="CST"
                                            placeholder="Insira o CST"
                                            error={!!errors.cst}
                                            inputProps={{
                                                maxLength: 2,
                                            }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment
                                                        position="end"
                                                    >
                                                        <IconButtonStyled
                                                            onClick={() => {
                                                                reset({ ...getValues(), cst: '' })
                                                            }}
                                                        >
                                                            <IoIosCloseCircleOutline />
                                                        </IconButtonStyled>
                                                    </InputAdornment>
                                            }}
                                        />
                                    )}
                                />
                                <Typography
                                    sx={{
                                        color: 'red'
                                    }}
                                >
                                    {!!errors.cst && errors.cst.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentStyled>
                    <DialogActionsStyled>
                        <CancelButton
                            onClick={handleClose}
                        >
                            Cancelar
                        </CancelButton>
                        <ApplyButton
                            type="submit"
                            label="Aplicar"
                            variant="contained"
                        />
                    </DialogActionsStyled>
                </form>
            </DialogStyled>
        </>
    )
}