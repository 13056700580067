import React from 'react'

import { Label, InputText, Div } from './style'

export default function TextField({ label, ...rest }) {
    return (
        <Div>
            <Label>{label}</Label>
            <InputText 
                autoComplete="off"
                fullWidth={true}
                variant="outlined"
                {...rest} 
            />
        </Div>
    )
}