import React from 'react'

import { MyButton } from './style'

export default function DefaultButton({ label, ...rest}) {
    return (
        <MyButton
            fullWidth={true}
            variant="contained" 
            {...rest}
        >
            {label}
        </MyButton>
    )
}