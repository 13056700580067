import React from 'react'
import { Container, Loader } from './style'
// import { Container, LoadingDots } from './style'

export default function CircularProgress() {
    return (
        <Container>
            <Loader></Loader>
        </Container>
    )
}