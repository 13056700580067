import styled from 'styled-components'

import { TableCell, TableHead, TableRow, IconButton } from '@mui/material'

import { lightGreen, mediumGray, paragraph } from '../../variables';

const TableHeadStyled = styled(TableHead)`
    && {
        th {
            color: ${mediumGray};
            font-size: ${paragraph};
            font-weight: 700;
        }
    }
`;

const TableRowStyled = styled(TableRow)`
    && {
        &:nth-of-type(odd) {
            background-color: ${lightGreen}11;
        }

        td, &:last-child th {
            border: 0;
        }

        td {
            color: ${mediumGray};
            font-size: ${paragraph};
            padding: 1em;
            font-weight: 400;
        }

        :hover {
            background-color: ${lightGreen}22;
            color: ${mediumGray};
            transition: .8s;
        }
    }
`;

const TableCellDesc = styled(TableCell)`
    && {
       width: 30%;
    }
`;

const IconButtonStyled = styled(IconButton)`
    && {
        opacity: .7;
        font-size: 1.2em;
    }
`;

export { 
    TableHeadStyled, 
    TableRowStyled,
    TableCellDesc, 
    IconButtonStyled,
}