import styled from 'styled-components';

import { TextField } from '@mui/material';

import { mediumGray, paragraph } from '../../variables'

const Div = styled.div`
    display: flex;
    flex-direction: column;
    
    input {
        height: 60px;
        padding: 0 1em 0 1em;
    }
`;

const Label = styled.label`
    font-size: ${paragraph};
    font-weight: 700;
    color: ${mediumGray}99;
    margin-bottom: 5px;
`;

const InputText = styled(TextField)`    
    && {
        div {
            padding-right: .1em;
        }

        div > div > button {
            visibility: hidden;
            transition: .1s;
        }

        div:hover > div > button {
            visibility: visible;
        }

        input {
            height: 60px;
            box-sizing: border-box;
            font-weight: 400;
        }
        
        .MuiOutlinedInput-input::placeholder {
            color: ${mediumGray};
            font-weight: 400;
        }
    }
`;

export { Div, Label, InputText }