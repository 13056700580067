import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RiSearchLine } from "react-icons/ri"
import { BiDownload } from "react-icons/bi"
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'

import {
    Box,
    Grid,
    TextField,
    CardContent,
    FormControl,
} from '@mui/material'
import { getFirstDayOfPreviousMonth, getLastDayOfPreviousMonth } from '../../utils/dateUtils'

import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '../../components/DefaultButton'
import DatePicker from '../../components/DatePicker'

import TitleCard from '../../components/Title'
import { CardStyled, DownloadButton } from '../../pages/Cfe/style'
import SelectStyled from '../../components/Select'
import { downloadNfesFornecedor, getNfesFornecedor } from '../../services/fornecedorService'
import TableFornecedor from '../../components/Tables/TableFornecedor'
import CircularProgress from '../../components/CircularProgress'
import CircularProgressDisabled from '../../components/CircularProgressDisabled'
import { Title } from '../../components/Title/style'

const validationSchema = yup.object({
    clienteId: yup.string().required("Selecione o cliente!"),
    dataDe: yup.date().required("Insira uma data!"),
})

export default function Fornecedor() {
    const [nfes, setNfes] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(0)

    async function onSubmit(values, page = 0) {
        setNfes([])
        setIsLoading(true)
        try {
            const { clienteId, dataDe, dataAte } = values
            const response = await getNfesFornecedor(clienteId, dataDe, dataAte, page)
            const _nfes = response.data.content
            setPage(page)
            setNfes(_nfes)
            setCount(response.data.totalElements)
            setTotal(response.data.total)
            setRowsPerPage(response.data.size)
        } catch (error) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setIsLoading(false)
        }
    }

    async function onDownload(values) {
        setDownloading(true)
        try {
            const { clienteId, dataDe, dataAte } = values
            const response = await downloadNfesFornecedor(clienteId, dataDe, dataAte)
            const fileName = response.headers["content-disposition"].split('filename=')[1];
            saveAs(response.data, fileName);
        } catch (error) {
            toast.error(error.response?.data?.message || error.message)
        } finally {
            setDownloading(false)
        }
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            clienteId: "",
            dataDe: getFirstDayOfPreviousMonth(),
            dataAte: getLastDayOfPreviousMonth()
        }
    })

    return (
        <FormControl
            onSubmit={handleSubmit((values) => onSubmit(values))}
            sx={{
                width: '100%',
            }}
        >
            <CardStyled>
                <CardContent>
                    <Box
                        component="form"
                    >
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <TitleCard
                                pageName="NF-e Fornecedor"
                                description="(Nota Fiscal Eletrônica - Fornecedor)"
                            />
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    padding: '1em 0'
                                }}
                            >
                                <Grid
                                    item
                                    sm={5}
                                >
                                    <Controller
                                        control={control}
                                        name="clienteId"
                                        render={({ field }) => (
                                            <SelectStyled
                                                value={field.value}
                                                onChange={(e) => {
                                                    setNfes([])
                                                    field.onChange(e)
                                                }}
                                                inputRef={field.ref}
                                                displayEmpty
                                                label="Clientes"
                                                error={!!errors.clienteId}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={5}
                                >
                                    <DatePicker
                                        control={control}
                                        errors={errors}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                >
                                    <Button
                                        type="submit"
                                        label="Buscar"
                                        startIcon={
                                            <RiSearchLine />
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    sx={{
                                        paddingTop: '0px!important',
                                        color: 'red'
                                    }}
                                >
                                    <span>
                                        {errors.clienteId?.message}
                                    </span>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    sx={{
                                        paddingTop: '0px!important',
                                        color: 'red'
                                    }}
                                >
                                    <span>
                                        {errors.dataDe && <span>Insira uma data de início e fim!</span>}
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={2}
                            >
                                <DownloadButton
                                    label="Download"
                                    onClick={handleSubmit(onDownload)}
                                    startIcon={
                                        downloading ? <CircularProgressDisabled /> : <BiDownload />
                                    }
                                    disabled={nfes.length === 0 || downloading}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </CardStyled>

            {
                isLoading && (<CircularProgress />)
            }

            {
                nfes.length === 0 &&
                <Grid
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {
                            !isLoading ?
                                <Title>
                                    Nenhum registro encontrado...
                                </Title> :
                                null
                        }
                    </Grid>
                </Grid>
            }


            {
                nfes.length > 0 &&
                <TableFornecedor
                    nfes={nfes}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    total={total}
                    onPageChange={(event, nextPage) => {
                        handleSubmit((values) => onSubmit(values, nextPage))()
                    }}
                />
            }
        </FormControl>



    )
}