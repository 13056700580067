import React from 'react'
import { Router } from 'react-router-dom'
import Routes from './routes'
import history from './services/history'
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/auth'
import 'react-toastify/dist/ReactToastify.css';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { ConfirmationServiceProvider } from './services/confirmationService';

function App() {
    const theme = createTheme({
        typography: {
            fontFamily: [
                "Open Sans",
                "sans-serif"
            ].join(','),
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <ConfirmationServiceProvider>
                    <Router history={history}>
                        <Routes />
                    </Router>
                </ConfirmationServiceProvider>
            </AuthProvider>
            <ToastContainer limit={3} />
        </ThemeProvider>
    )
}

export default App