import React from 'react'

import { MyButton } from './style'

export default function CancelButton({ ...rest }) {
    return (
        <MyButton
            variant="contained" 
            {...rest}
        >
            Cancelar
        </MyButton>
    )
}