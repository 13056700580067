import styled from 'styled-components'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import Button from '../../components/DefaultButton'
import { darkGray, lightGray, subtitle, mediumGreen } from '../../variables'

// Dialog

const DialogStyled = styled(Dialog)`
    && {
        .MuiPaper-root {
            padding: 2em;
            border-radius: 10px;
            margin: 0;
        }
    }
`;

const DialogTitleStyled = styled(DialogTitle)`
    && {
        padding: 0;
        font-size: ${subtitle};
        font-weight: 700;
        text-align: left;
        color: ${darkGray};
        border-bottom: 1px solid ${lightGray};
    }
`;

const DialogContentStyled = styled(DialogContent)`
    && {
        padding: 0;
        margin: 1em 0;
    }
`;

const DialogActionsStyled = styled(DialogActions)`
    && {
        justify-content: space-evenly;
        padding: 0;
    }
`;

const ApplyButton = styled(Button)`
    && {
        width: 30%;
    }
`;

const ClearFilters = styled(Button)`
    && {
        :disabled {
            background-color: ${mediumGreen}88;
        }
    }
`;

export {
    ApplyButton,
    DialogStyled,
    ClearFilters,
    DialogTitleStyled,
    DialogContentStyled,
    DialogActionsStyled,
}